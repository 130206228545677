// Angular Core
import { provideAppInitializer, inject, importProvidersFrom, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { provideRouter, withComponentInputBinding } from '@angular/router';

// Angular Material
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatPaginatorIntl } from '@angular/material/paginator';
// NgRx
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
// Third-party Modules
import { provideHighlightOptions } from 'ngx-highlightjs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
// Transloco
import { provideTransloco, translocoConfig, TranslocoService } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
// Services
import { preloadI18nLocale, CommonService, MatPaginatorI18nService, LocaleProvider, TokenInterceptor } from 'src/app/core/utils';
import { AuthService } from '@auth-service';
// Store
import { reducers, metaReducers } from './core/reducers';
import { AuthEffects, authReducer } from './core/auth';
// Environment
import { environment } from '@environment';
// Routes
import { appRoutes } from './app.routes';
// Constants
import { APP_CONSTANT } from '@constants';

// Application Config
export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            NgxPermissionsModule.forRoot(),
            InlineSVGModule.forRoot()
        ),
        provideStore(reducers, { metaReducers }),
        provideRouterStore({ stateKey: 'router' }), // Router state integration
        provideState('auth', authReducer),
        provideEffects(AuthEffects),
        provideRouter(appRoutes, withComponentInputBinding()),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAppInitializer(() => {
            const initializerFn = (preloadI18nLocale)(
                inject(AuthService),
                inject(CommonService),
                inject(TranslocoService)
            );
            return initializerFn();
        }),
        provideHighlightOptions({
            coreLibraryLoader: () => import('highlight.js/lib/core'),
            languages: {
                xml: () => import('highlight.js/lib/languages/xml'),
                typescript: () => import('highlight.js/lib/languages/typescript'),
                scss: () => import('highlight.js/lib/languages/scss'),
                json: () => import('highlight.js/lib/languages/json'),
            }
        }),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000
            }
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nService
        },
        LocaleProvider,
        provideHttpClient(withInterceptors([TokenInterceptor])),
        provideAnimations(),
        provideTransloco({
            config: translocoConfig({
                availableLangs: ['da-dk', 'de-de', 'es-es', 'fi-fi', 'fr-fr', 'is-is', 'it-it', 'nb-no', 'en-se', 'sv-se'],
                defaultLang: 'en-se',
                fallbackLang: 'en-se',
                reRenderOnLangChange: false,
                prodMode: environment.production
            }),
            loader: TranslocoHttpLoader,
        }),
        provideTranslocoPersistLang({
            storage: {
                useValue: localStorage
            },
            storageKey: APP_CONSTANT.USER_LANGUAGE_KEY
        }),
        provideLoadingBarRouter(),
        provideServiceWorker('ngsw-worker.js', { enabled: environment.production })
    ]
};